.leaderboard {
  .gold-item {
    border-radius: 10px;
    margin-bottom: 4px;
    border-left: 2px solid rgba(237, 201, 103, 1);
    border-top: 1px solid rgba(237, 201, 103, 0.5);
    border-right: 1px solid rgba(237, 201, 103, 0.5);
    border-bottom: 1px solid rgba(237, 201, 103, 0.5);
    background: linear-gradient(
        90deg,
        rgba(237, 201, 103, 0.3) 0%,
        rgba(237, 201, 103, 0) 15.62%
      ),
      rgba(237, 201, 103, 0.05);
  }

  .silver-item {
    border-radius: 10px;
    margin-bottom: 4px;
    border-left: 2px solid rgba(219, 228, 235, 1);
    border-top: 1px solid rgba(219, 228, 235, 0.5);
    border-right: 1px solid rgba(219, 228, 235, 0.5);
    border-bottom: 1px solid rgba(219, 228, 235, 0.5);
    background: linear-gradient(
        90deg,
        rgba(219, 228, 235, 0.3) 0%,
        rgba(219, 228, 235, 0) 15.62%
      ),
      rgba(219, 228, 235, 0.05);
  }

  .bronze-item {
    border-radius: 10px;
    margin-bottom: 4px;
    border-left: 2px solid rgba(191, 137, 112, 1);
    border-top: 1px solid rgba(191, 137, 112, 0.5);
    border-right: 1px solid rgba(191, 137, 112, 0.5);
    border-bottom: 1px solid rgba(191, 137, 112, 0.5);
    background: linear-gradient(
        90deg,
        rgba(191, 137, 112, 0.3) 0%,
        rgba(191, 137, 112, 0) 15.62%
      ),
      rgba(191, 137, 112, 0.05);
  }

  .table-header-box {
    border-radius: 10px;
    border: 1px solid #11161b;
    background: rgba(17, 22, 27, 0.3);
    box-shadow: 0px 0px 6px 6px #11161b inset;
    margin-bottom: 12px;
  }

  .time-filter-box,
  .sort-box {
    padding: 8px 30px;
    border-radius: 10px;
    border: 1px solid #11161b;
    background: rgba(17, 22, 27, 0.3);
    box-shadow: 0px 0px 10px 6px #11161b inset;
  }

  .current-user-item {
    border-radius: 10px;
    margin-bottom: 4px;
    margin-top: 12px;
    border-left: 2px solid rgba(0, 165, 254, 1);
    border-top: 1px solid rgba(0, 165, 254, 0.5);
    border-right: 1px solid rgba(0, 165, 254, 0.5);
    border-bottom: 1px solid rgba(0, 165, 254, 0.5);
    background: linear-gradient(
        90deg,
        rgba(0, 165, 254, 0.3) 0%,
        rgba(0, 165, 254, 0) 15.62%
      ),
      rgba(0, 165, 254, 0.05);
  }

  .wager-race-reward {
    color: #00a5fe;
    text-shadow: 0px 0px 4px #00a5fe;
    font-size: 16px;
  }

  .hover-effect {
    transition: color 0.3s ease, transform 0.3s ease;
  }

  .hover-effect:hover {
    color: white;
    transform: scale(1.1);
  }
}
