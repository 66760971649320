@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@solana/wallet-adapter-react-ui/styles.css";
@import "react-toastify/dist/ReactToastify.css";
@import "react-multi-carousel/lib/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700&display=swap");

iframe#webpack-dev-server-client-overlay {
  display: none !important
}

html {
  /* for firefox  */
  scrollbar-width: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: scroll;
  background-color: #11161B;
  color: white;
}

* {
  box-sizing: border-box;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input {
  -webkit-user-select: text !important;
  /* Chrome, Opera, Safari */
  -moz-user-select: text !important;
  /* Firefox 2+ */
  -ms-user-select: text !important;
  /* IE 10+ */
  user-select: text !important;
  /* Standard syntax */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.thin-scroll-bar {

  /* for firefox */
  scrollbar-width: none;

  /* width */
  &::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.gradient-btn-no-bg {
  background: linear-gradient(rgb(17 22 27 / 1), rgb(17 22 27 / 1)) padding-box,
    linear-gradient(90deg, #9f263c, #a52483, #9d64b5, #1360b1, #00a5fe) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
}

.gradient-btn {
  position: relative;
  background: linear-gradient(90deg,
      rgba(159, 38, 60, 0.3),
      rgba(165, 36, 131, 0.3),
      rgba(157, 100, 181, 0.3),
      rgba(19, 96, 177, 0.3),
      rgba(0, 165, 254, 0.3));
  border-radius: 10px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: 2px solid transparent;
    background: linear-gradient(45deg,
        rgba(159, 38, 60, 1),
        rgba(165, 36, 131, 1),
        rgba(157, 100, 181, 1),
        rgba(19, 96, 177, 1),
        rgba(0, 165, 254, 1)) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  &.full-rounded {
    border-radius: 9999px;

    &::before {
      border-radius: 9999px;
    }
  }

  i {
    display: none;
  }
}

.gray-gradient-btn {
  background: linear-gradient(90deg, rgba(128, 128, 128, 0.3) 6.65%, rgba(128, 128, 128, 0.09) 23.96%, rgba(128, 128, 128, 0.27) 50.23%, rgba(128, 128, 128, 0.15) 92.03%);
  border: 2px solid rgba(141, 141, 141, 0.2);
}

.wallet-adapter-button {
  height: 40px;

  &:hover {
    background-color: #03060A
  }
}

.left-winner-gradient-bg {
  background: linear-gradient(89.96deg, rgba(102, 255, 182, 0.42) 0.04%, rgba(102, 255, 182, 0) 50%), #11161B;
}

.right-winner-gradient-bg {
  background: linear-gradient(270deg, rgba(102, 255, 182, 0.42) 0%, rgba(102, 255, 182, 0) 50%), #11161B;
}

.draw-gradient-bg {
  background: linear-gradient(270deg, rgba(237, 201, 103, 0.4) 0%, rgba(237, 201, 103, 0) 50.17%, rgba(237, 201, 103, 0.4) 100%), #11161B;
}

.modal-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1050;
  max-width: 390px;
  width: 100%;
  border-radius: 10px;
  font-weight: 600;
  padding: 36px 30px 30px;
  flex: 1;
  text-align: center;
  background: #090C0E;
  box-shadow: 0px 0px 7.1746px #00A5FE;

  .coinChooser {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 15px;
    cursor: pointer;

    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border: 3px solid transparent;
      border-radius: 50%;
      transition: 0.3s;
    }

    .chosen {
      border: 3px solid white;
      border-radius: 50%;
      transition: 0.3s;
      transform: scale(1.05);
      box-shadow: rgb(255 255 255 / 25%) 0px 54px 55px,
        rgb(0 0 0 / 12%) 0px -12px 30px, rgb(255 252 255 / 12%) 0px 4px 6px,
        rgb(0 0 0 / 17%) 0px 12px 13px, rgb(255 255 255) 0px -1px 5px;
    }
  }
}

/* rps animation */
.rock-rock {
  background-image: url('./assets/rock-rock.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.rock-paper {
  background-image: url('./assets/rock-paper.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.rock-scissor {
  background-image: url('./assets/rock-scissor.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.paper-paper {
  background-image: url('./assets/paper-paper.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.paper-rock {
  background-image: url('./assets/paper-rock.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.paper-scissor {
  background-image: url('./assets/paper-scissor.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.scissor-scissor {
  background-image: url('./assets/scissor-scissor.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.scissor-rock {
  background-image: url('./assets/scissor-rock.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.scissor-paper {
  background-image: url('./assets/scissor-paper.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.win-bg {
  background-image: url('./assets/win.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.lose-bg {
  background-image: url('./assets/lose.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.draw-bg {
  background-image: url('./assets/draw.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* ------------- */
@font-face {
  font-family: "Inter-Regualer";
  src: url("./fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Tisa Sans Pro Black";
  src: url("./fonts/TisaSansPro-BlackItalic.eot");
  src: local("./fonts/Tisa Sans Pro Black Italic"),
    local("./fonts/TisaSansPro-BlackItalic"),
    url("./fonts/TisaSansPro-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-BlackItalic.woff2") format("woff2"),
    url("./fonts/TisaSansPro-BlackItalic.woff") format("woff"),
    url("./fonts/TisaSansPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Tisa Sans Pro";
  src: url("./fonts/TisaSansPro-Bold.eot");
  src: local("./fonts/Tisa Sans Pro Bold"), local("./fonts/TisaSansPro-Bold"),
    url("./fonts/TisaSansPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-Bold.woff2") format("woff2"),
    url("./fonts/TisaSansPro-Bold.woff") format("woff"),
    url("./fonts/TisaSansPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Tisa Sans Pro Med";
  src: url("./fonts/TisaSansPro-MediumItalic.eot");
  src: local("./fonts/Tisa Sans Pro Medium Italic"),
    local("./fonts/TisaSansPro-MediumItalic"),
    url("./fonts/TisaSansPro-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-MediumItalic.woff2") format("woff2"),
    url("./fonts/TisaSansPro-MediumItalic.woff") format("woff"),
    url("./fonts/TisaSansPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Tisa Sans Pro ExtBd";
  src: url("./fonts/TisaSansPro-ExtraBoldItalic.eot");
  src: local("./fonts/Tisa Sans Pro Extra Bold Italic"),
    local("./fonts/TisaSansPro-ExtraBoldItalic"),
    url("./fonts/TisaSansPro-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-ExtraBoldItalic.woff2") format("woff2"),
    url("./fonts/TisaSansPro-ExtraBoldItalic.woff") format("woff"),
    url("./fonts/TisaSansPro-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Tisa Sans Pro";
  src: url("./fonts/TisaSansPro-BoldItalic.eot");
  src: local("./fonts/Tisa Sans Pro Bold Italic"),
    local("./fonts/TisaSansPro-BoldItalic"),
    url("./fonts/TisaSansPro-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-BoldItalic.woff2") format("woff2"),
    url("./fonts/TisaSansPro-BoldItalic.woff") format("woff"),
    url("./fonts/TisaSansPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Tisa Sans Pro";
  src: url("./fonts/TisaSansPro-LightItalic.eot");
  src: local("./fonts/Tisa Sans Pro Light Italic"),
    local("./fonts/TisaSansPro-LightItalic"),
    url("./fonts/TisaSansPro-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-LightItalic.woff2") format("woff2"),
    url("./fonts/TisaSansPro-LightItalic.woff") format("woff"),
    url("./fonts/TisaSansPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Tisa Sans Pro ExtBd";
  src: url("./fonts/TisaSansPro-ExtraBold.eot");
  src: local("./fonts/Tisa Sans Pro Extra Bold"),
    local("./fonts/TisaSansPro-ExtraBold"),
    url("./fonts/TisaSansPro-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-ExtraBold.woff2") format("woff2"),
    url("./fonts/TisaSansPro-ExtraBold.woff") format("woff"),
    url("./fonts/TisaSansPro-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Tisa Sans Pro Med";
  src: url("./fonts/TisaSansPro-Medium.eot");
  src: local("./fonts/Tisa Sans Pro Medium"), local("./fonts/TisaSansPro-Medium"),
    url("./fonts/TisaSansPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-Medium.woff2") format("woff2"),
    url("./fonts/TisaSansPro-Medium.woff") format("woff"),
    url("./fonts/TisaSansPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Tisa Sans Pro";
  src: url("./fonts/TisaSansPro-Black.eot");
  src: local("./fonts/Tisa Sans Pro Black"), local("./fonts/TisaSansPro-Black"),
    url("./fonts/TisaSansPro-Black.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-Black.woff2") format("woff2"),
    url("./fonts/TisaSansPro-Black.woff") format("woff"),
    url("./fonts/TisaSansPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Tisa Sans Pro";
  src: url("./fonts/TisaSansPro-Regular.eot");
  src: local("./fonts/Tisa Sans Pro Regular"),
    local("./fonts/TisaSansPro-Regular"),
    url("./fonts/TisaSansPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-Regular.woff2") format("woff2"),
    url("./fonts/TisaSansPro-Regular.woff") format("woff"),
    url("./fonts/TisaSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tisa Sans Pro ExtLt";
  src: url("./fonts/TisaSansPro-ExtraLightItalic.eot");
  src: local("./fonts/Tisa Sans Pro ExtLt Ita"),
    local("./fonts/TisaSansPro-ExtraLightItalic"),
    url("./fonts/TisaSansPro-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-ExtraLightItalic.woff2") format("woff2"),
    url("./fonts/TisaSansPro-ExtraLightItalic.woff") format("woff"),
    url("./fonts/TisaSansPro-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Tisa Sans Pro";
  src: url("./fonts/TisaSansPro-Light.eot");
  src: local("./fonts/Tisa Sans Pro Light"), local("./fonts/TisaSansPro-Light"),
    url("./fonts/TisaSansPro-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-Light.woff2") format("woff2"),
    url("./fonts/TisaSansPro-Light.woff") format("woff"),
    url("./fonts/TisaSansPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Tisa Sans Pro ExtLt";
  src: url("./fonts/TisaSansPro-ExtraLight.eot");
  src: local("./fonts/Tisa Sans Pro Extra Light"),
    local("./fonts/TisaSansPro-ExtraLight"),
    url("./fonts/TisaSansPro-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-ExtraLight.woff2") format("woff2"),
    url("./fonts/TisaSansPro-ExtraLight.woff") format("woff"),
    url("./fonts/TisaSansPro-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Tisa Sans Pro";
  src: url("./fonts/TisaSansPro-Italic.eot");
  src: local("./fonts/Tisa Sans Pro Italic"), local("./fonts/TisaSansPro-Italic"),
    url("./fonts/TisaSansPro-Italic.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TisaSansPro-Italic.woff2") format("woff2"),
    url("./fonts/TisaSansPro-Italic.woff") format("woff"),
    url("./fonts/TisaSansPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

* {
  font-family: "Tisa Sans Pro", sans-serif;
}

@layer utilities {
  .sidebar-link-item {
    padding-left: 38px;
    font-family: 'Tisa Sans Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    height: 61px;
    color: #808080;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover:not(.active) {
      transform: scale(1.2);
      filter: brightness(110%);
    }

    &.active {
      border-left: 3px solid;
      border-image-source: linear-gradient(196deg, #9F263C 45.19%, #A52483 51.93%, #9D64B5 69.18%, #1360B1 90.61%, #00A5FE 100%);
      border-image-slice: 1;
      background: linear-gradient(90deg, #1D262F 0%, rgba(0, 0, 0, 0) 100%);
      color: white;
      font-weight: bold;
    }
  }

  .message-box-icon {
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  .rps-winner {
    border: 2px solid rgba(249, 2, 238);
    box-shadow: inset 0px 0px 3.5px 1px rgb(249, 2, 238);
    border-radius: 10px;
  }

  .rps-loser {
    opacity: 0.6;
  }

  .flip {
    transform: rotateY(180deg);
  }

  .no-scroll-bar {
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0px
    }

    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .font-primary {
    font-family: "Tisa Sans Pro", sans-serif;
  }

  .font-inter {
    font-family: "Inter-Regualer";
  }

  .font-orbitron {
    font-family: "Orbitron";
  }

  .gold-font {
    background: linear-gradient(90deg, #AE8625 0%, #F7EFA8 46.35%, #D2AC47 75.52%, #EDC967 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.social-links a:hover img {
  filter: brightness(0%) invert(100%);
}

.dash-links a {
  img {
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.2);
      filter: brightness(110%);
    }
  }
}

.button-chat {
  &:hover {
    transform: scale(1.1);
    filter: brightness(0%) invert(100%);
  }
}

.break-anywhere {
  overflow-wrap: anywhere;
}

.apexcharts-tooltip {
  color: black;
}

.EmojiPickerReact {
  width: 100% !important;
  background-color: #090C0E !important;
  box-shadow: 0px 0px 8px #00A5FE;
  // border: 1px solid #1360B1 !important;

  .epr-search-container {
    .epr-search {
      border: none !important;
      background-color: #1D262F !important;
    }
  }

  .epr-emoji-category-label {
    background-color: #11161Be6 !important;
  }

  .epr-body {
    @extend .thin-scroll-bar;

    /* width */
    &::-webkit-scrollbar {
      width: 3.5px;
    }
  }
}

.chat-emoji {
  width: 16px;
  display: inline-block;
  vertical-align: sub;
}

.GifPickerReact {
  width: 100% !important;
  background-color: #090C0E !important;
  box-shadow: 0px 0px 8px #00A5FE;

  .gpr-search-container {
    .gpr-search {
      border: none !important;
      background-color: #1D262F !important;
    }
  }

  .gpr-placeholder {
    background-color: #1D262F !important;
  }

  .gpr-body {

    .gpr-category-list,
    .gpr-gif-list {
      @extend .thin-scroll-bar;

      /* width */
      &::-webkit-scrollbar {
        width: 3.5px !important;
      }
    }
  }
}

.new-badge-text {
  color: #F7EFA8;
}

.arena-title {
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #F5791F;
}

.arena-text {
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: black;
}