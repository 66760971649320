.dice2-countdown-bar {
    background: #000000;
    font-size: 15px;
    /* Adjust the value as needed */
    font-weight: bold;
    height: 33px;
    /* Increase this value to make the bar thicker */
    border-radius: 0;
    /* Set border-radius to 0 to remove rounded edges */

    @media only screen and (max-width: 768px) {
        height: 21px;
        font-size: 12px;
        font-weight: 400;
    }


    .dice2-progress-bar {
        max-width: 102%;
        transform-origin: left center;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 0, 0, 0.20) 75.98%), linear-gradient(90deg, rgba(7, 153, 240, 0.60) 0%, rgba(71, 96, 178, 0.60) 19.78%, rgba(107, 33, 201, 0.60) 36.2%, rgba(255, 228, 155, 0.60) 60.94%, rgba(237, 201, 103, 0.60) 77.54%, rgba(255, 122, 26, 0.60) 100%);
        animation: roundtime calc(var(--duration) * 1ms) linear forwards;
        height: 33px;
        /* Increase this value to make the bar thicker */

        @media only screen and (max-width: 768px) {
            height: 21px;
        }
    }

    @keyframes roundtime {
        from {
            /* More performant than `width` */
            width: calc(100% * calc(var(--duration) / var(--initial)));
        }

        to {
            /* More performant than `width` */
            width: 0;
        }
    }
}

.last-flip-glow-and-fade {
    will-change: transform, opacity;
    animation-name: fadeInDropBounce;
    animation-duration: 500ms;
    /* adjust the duration as desired */
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    box-shadow: 0 0 6px rgba(255, 255, 255, 0.6), 0 0 6px rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    /* Adjust this value to match the shape of your image */
}

@keyframes fadeInDropBounce {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }

    30% {
        opacity: 1;
        transform: translateY(0);
    }

    70% {
        transform: translateY(-10%);
        /* control the bounce height by adjusting this value */
    }

    100% {
        transform: translateY(0);
    }
}

.fifth-flip {
    will-change: transform, opacity;
    animation-name: moveDownFadeOut;
    animation-duration: 500ms;
    /* adjust the duration as desired */
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.roll-ccw {
    will-change: transform;
    transform-origin: center;
    animation-name: rollCounterClockwise;
    animation-duration: 500ms;
    /* adjust the duration as desired */
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes rollCounterClockwise {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
        /* adjust the value to control the distance it moves */
    }
}

.hover-bet-buttons {
    position: relative;
}

.hover-bet-buttons.active {
    background-color: #03060A;
    box-shadow: 0px 0px 9.14062px #00A5FE;
    border: 2px solid #00A5FE;
    transition: background-color 0.3s ease;
    transform: translateY(2px);
}

.hover-bet-buttons:hover {
    color: white;
}

.hover-bet-buttons.active:hover {
    background-color: #03060A;
}

/* Free bet */
.free-bet-buttons {
    position: relative;
}

.free-bet-buttons.active {
    background-color: #03060A;
    box-shadow: 0px 0px 9.14062px #46ff78;
    border: 2px solid #46ff78;
    transition: background-color 0.3s ease;
    transform: translateY(2px);
    color: #46ff78;
}

.free-bet-buttons:hover {
    color: white;
}

.free-bet-buttons.active:hover {
    background-color: #03060A;
}

/* -------- */

.dice2-btn-1 {
    position: relative;
    background: linear-gradient(90deg,
            rgba(5, 244, 230, 0.1),
            rgba(104, 153, 248, 0.1),
            rgba(10, 57, 180, 0.1),
            rgba(19, 96, 177, 0.1),
            rgba(0, 165, 254, 0.1));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(5, 244, 230, 1),
                rgba(104, 153, 248, 1),
                rgba(10, 57, 180, 1),
                rgba(19, 96, 177, 1),
                rgba(0, 165, 254, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.dice2-btn-2 {
    position: relative;
    background: linear-gradient(90deg,
            rgba(174, 134, 37, 0.1),
            rgba(247, 239, 168, 0.1),
            rgba(210, 172, 71, 0.1),
            rgba(237, 201, 103, 0.1));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(174, 134, 37, 1),
                rgba(247, 239, 168, 1),
                rgba(210, 172, 71, 1),
                rgba(237, 201, 103, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.dice2-btn-3 {
    position: relative;
    background: linear-gradient(90deg,
            rgba(176, 149, 255, 0.10),
            rgba(159, 141, 211, 0.10),
            rgba(107, 33, 201, 0.10),
            rgba(128, 86, 246, 0.10));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(176, 149, 255, 1),
                rgba(159, 141, 211, 1),
                rgba(107, 33, 201, 1),
                rgba(128, 86, 246, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.dice2-btn-4 {
    position: relative;
    background: linear-gradient(90deg,
            rgba(255, 178, 122, 0.10),
            rgba(255, 210, 177, 0.10),
            rgba(211, 96, 12, 0.10),
            rgba(121, 51, 0, 0.10),
            rgba(255, 122, 26, 0.10));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(255, 178, 122, 1),
                rgba(255, 210, 177, 1),
                rgba(211, 96, 12, 1),
                rgba(121, 51, 0, 1),
                rgba(255, 122, 26, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.dice2-btn:hover {
    transform: scale(1.02);
    box-shadow: 0 0 10px 0.5px rgb(253, 253, 253);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dice2-win-board {
    // border: 3.5px solid rgba(0, 255, 0, 0.5);
    border-radius: 2px;
    box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 0.5), 0px 0px 16px 2px rgba(0, 255, 0, 0.5);
    transition: all 0.5s ease;
    animation: pulse 2s infinite;

    &:hover {
        border: 3.5px solid rgba(0, 255, 0, 1);
        box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 1), 0px 0px 16px 2px rgba(0, 255, 0, 1);
        transform: translateY(-0.1px);
        /* Reduce the amount of translateY */
        transition: transform 0.3s ease;
    }
}

.relative {
    position: relative;
}

.special-hover {
    display: block;
    position: absolute;
    background: #00A5FE;
    color: white;
}

.special-hover.free-bet {
    display: block;
    position: absolute;
    background: #46ff78;
    color: #46ff78;
}

.special-hover:nth-child(1) {
    left: -2px;
    bottom: -2px;
    width: 2px;
    height: calc(100% + 4px);
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s;
}

button:hover .special-hover:nth-child(1) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.2s;
}

.special-hover:nth-child(2) {
    left: 0;
    bottom: -2px;
    width: calc(100% + 2px);
    height: 2px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s;
}

button:hover .special-hover:nth-child(2) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.2s;
}

.special-hover:nth-child(3) {
    right: -2px;
    bottom: 0;
    width: 2px;
    height: calc(100% + 2px);
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

button:hover .special-hover:nth-child(3) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

.special-hover:nth-child(4) {
    left: 0;
    top: -2px;
    width: calc(100% + 2px);
    height: 2px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

button:hover .special-hover:nth-child(4) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

/* Updated selectors from button:hover to .hover-bet-buttons:hover */
.hover-bet-buttons:hover .special-hover:nth-child(1) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.2s;
}

.hover-bet-buttons:hover .special-hover:nth-child(2) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.2s;
}

.hover-bet-buttons:hover .special-hover:nth-child(3) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

.hover-bet-buttons:hover .special-hover:nth-child(4) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

/* Add these new rules to override the hover effect for the active state */
.hover-bet-buttons.active:hover .special-hover:nth-child(1),
.hover-bet-buttons.active:hover .special-hover:nth-child(3) {
    transform: scaleY(0);
    transition: none;
}

.hover-bet-buttons.active:hover .special-hover:nth-child(2),
.hover-bet-buttons.active:hover .special-hover:nth-child(4) {
    transform: scaleX(0);
    transition: none;
}

.win-header {
    background: linear-gradient(90deg, rgba(55, 207, 96, 0.3) 0%, rgba(154, 255, 181, 0.3) 46.35%, rgba(0, 185, 63, 0.3) 75.52%, rgba(0, 255, 133, 0.3) 100%);
    transition: transform 0.3s ease;
    /* Add transition effect */
}

.win-numbers {
    color: #46FF78;
    transition: transform 0.3s ease;
    /* Add transition effect */
}

.win-user-group {
    content: url("../../assets/win-user-group.svg");
}

.win-user {
    content: url("../../assets/win-user.svg");
}

.win-sol-symbol {
    content: url("../../assets/win-sol-symbol.svg");
}

.recent-flip-image {
    opacity: 0;
    transition: opacity 0.5s ease;
    image-rendering: -webkit-optimize-contrast;
}

.recent-flip-image.loaded {
    opacity: 1;
}

/* Dice roll */
.dice2 {
    position: relative;
    transform-style: preserve-3d;
    transform: rotateY(180deg) rotateX(150deg) rotateZ(315deg);
    transition: transform 1.2s ease-out;
}

.dice2 {
    align-items: center;
    display: grid;
    grid-gap: calc(2rem/1.2);
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
    grid-template-rows: auto;
    justify-items: center;
    perspective: 600px;
}

.dice2-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: calc(6rem/1.2);
    list-style-type: none;
    transform-style: preserve-3d;
    width: calc(6rem/1.2);
}

.dice2-item {
    background: linear-gradient(49deg, rgba(128, 86, 246, 0.60) 17.59%, rgba(0, 0, 0, 0.00) 78.62%), linear-gradient(275deg, rgba(255, 0, 0, 0.60) 9.36%, rgba(0, 0, 0, 0.00) 81.65%), linear-gradient(232deg, rgba(237, 201, 103, 0.80) 25.63%, rgba(0, 0, 0, 0.00) 76.17%), linear-gradient(119deg, #00A5FE 18.56%, rgba(0, 0, 0, 0.00) 76.34%), linear-gradient(180deg, #000 0%, #000 71.14%, #D0EFFF 100%);
    box-shadow: inset calc(-0.35rem/1.2) calc(-0.35rem/1.2) calc(0.75rem/1.2) rgba(0, 0, 0, 0.3),
        inset calc(0.5rem/1.2) calc(-0.25rem/1.2) calc(0.5rem/1.2) rgba(0, 0, 0, 0.15);
    display: grid;
    grid-column: 1;
    grid-row: 1;
    grid-template-areas:
        "one two three"
        "four five six"
        "seven eight nine";
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    height: 100%;
    padding: 5px;
    width: 100%;
}

.dice2-dot {
    align-self: center;
    background-color: white;
    border-radius: 50%;
    box-shadow: inset calc(-0.15rem/1.2) calc(0.15rem/1.2) calc(0.25rem/1.2) rgba(0, 0, 0, 0.5);
    display: block;
    height: calc(1.25rem/1.2);
    justify-self: center;
    width: calc(1.25rem/1.2);
}

[dice2-data-side="1"] {
    transform: rotate3d(0, 0, 0, 90deg) translateZ(calc(3rem/1.2));
}

[dice2-data-side="2"] {
    transform: rotate3d(-1, 0, 0, 90deg) translateZ(calc(3rem/1.2));
}

[dice2-data-side="3"] {
    transform: rotate3d(0, 1, 0, 90deg) translateZ(calc(3rem/1.2));
}

[dice2-data-side="4"] {
    transform: rotate3d(0, -1, 0, 90deg) translateZ(calc(3rem/1.2));
}

[dice2-data-side="5"] {
    transform: rotate3d(1, 0, 0, 90deg) translateZ(calc(3rem/1.2));
}

[dice2-data-side="6"] {
    transform: rotate3d(1, 0, 0, 180deg) translateZ(calc(3rem/1.2));
}

@media only screen and (min-width: 768px) {
    .dice2 {
        align-items: center;
        display: grid;
        grid-gap: calc(2rem/0.75);
        grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
        grid-template-rows: auto;
        justify-items: center;
        perspective: 600px;
    }

    .dice2-list {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        height: calc(6rem/0.75);
        list-style-type: none;
        transform-style: preserve-3d;
        width: calc(6rem/0.75);
    }

    .dice2-item {
        background: linear-gradient(49deg, rgba(128, 86, 246, 0.60) 17.59%, rgba(0, 0, 0, 0.00) 78.62%), linear-gradient(275deg, rgba(255, 0, 0, 0.60) 9.36%, rgba(0, 0, 0, 0.00) 81.65%), linear-gradient(232deg, rgba(237, 201, 103, 0.80) 25.63%, rgba(0, 0, 0, 0.00) 76.17%), linear-gradient(119deg, #00A5FE 18.56%, rgba(0, 0, 0, 0.00) 76.34%), linear-gradient(180deg, #000 0%, #000 71.14%, #D0EFFF 100%);
        box-shadow: inset calc(-0.35rem/0.75) calc(-0.35rem/0.75) calc(0.75rem/0.75) rgba(0, 0, 0, 0.3),
            inset calc(0.5rem/0.75) calc(-0.25rem/0.75) calc(0.5rem/0.75) rgba(0, 0, 0, 0.15);
        display: grid;
        grid-column: 1;
        grid-row: 1;
        grid-template-areas:
            "one two three"
            "four five six"
            "seven eight nine";
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        height: 100%;
        padding: 5px;
        width: 100%;
    }

    .dice2-dot {
        align-self: center;
        background-color: white;
        border-radius: 50%;
        box-shadow: inset calc(-0.15rem/0.75) calc(0.15rem/0.75) calc(0.25rem/0.75) rgba(0, 0, 0, 0.5);
        display: block;
        height: calc(1.25rem/0.75);
        justify-self: center;
        width: calc(1.25rem/0.75);
    }

    [dice2-data-side="1"] {
        transform: rotate3d(0, 0, 0, 90deg) translateZ(calc(3rem/0.75));
    }

    [dice2-data-side="2"] {
        transform: rotate3d(-1, 0, 0, 90deg) translateZ(calc(3rem/0.75));
    }

    [dice2-data-side="3"] {
        transform: rotate3d(0, 1, 0, 90deg) translateZ(calc(3rem/0.75));
    }

    [dice2-data-side="4"] {
        transform: rotate3d(0, -1, 0, 90deg) translateZ(calc(3rem/0.75));
    }

    [dice2-data-side="5"] {
        transform: rotate3d(1, 0, 0, 90deg) translateZ(calc(3rem/0.75));
    }

    [dice2-data-side="6"] {
        transform: rotate3d(1, 0, 0, 180deg) translateZ(calc(3rem/0.75));
    }
}

// dice1 roll
.dice21-roll {
    animation: dice21Roll 1.2s ease-out forwards;
}

// dice2 roll
.dice22-roll {
    animation: dice22Roll 1.2s ease-out forwards;
}

// dice3 roll
.dice23-roll {
    animation: dice23Roll 1.2s ease-out forwards;
}

// dice4 roll
.dice24-roll {
    animation: dice24Roll 1.2s ease-out forwards;
}

// dice5 roll
.dice25-roll {
    animation: dice25Roll 1.2s ease-out forwards;
}

// dice6 roll
.dice26-roll {
    animation: dice26Roll 1.2s ease-out forwards;
}

@keyframes dice21Roll {
    0% {
        transform: rotateX(360deg) rotateY(720deg) rotateZ(360deg);
    }

    100% {
        transform: rotateX(-360deg) rotateY(-720deg) rotateZ(-360deg);
    }
}

@keyframes dice22Roll {
    0% {
        transform: rotateX(450deg) rotateY(720deg) rotateZ(360deg);
    }

    100% {
        transform: rotateX(-270deg) rotateY(-720deg) rotateZ(-360deg);
    }
}

@keyframes dice23Roll {
    0% {
        transform: rotateX(360deg) rotateY(630deg) rotateZ(360deg);
    }

    100% {
        transform: rotateX(-360deg) rotateY(-810deg) rotateZ(-360deg);
    }
}

@keyframes dice24Roll {
    0% {
        transform: rotateX(360deg) rotateY(810deg) rotateZ(360deg);
    }

    100% {
        transform: rotateX(-360deg) rotateY(-630deg) rotateZ(-360deg);
    }
}

@keyframes dice25Roll {
    0% {
        transform: rotateX(270deg) rotateY(720deg) rotateZ(360deg);
    }

    100% {
        transform: rotateX(-450deg) rotateY(-720deg) rotateZ(-360deg);
    }
}

@keyframes dice26Roll {
    0% {
        transform: rotateX(360deg) rotateY(900deg) rotateZ(360deg);
    }

    100% {
        transform: rotateX(-360deg) rotateY(-900deg) rotateZ(-360deg);
    }
}

[dice2-data-side="1"] .dice2-dot:nth-of-type(1) {
    grid-area: five;
}

[dice2-data-side="2"] .dice2-dot:nth-of-type(1) {
    grid-area: one;
}

[dice2-data-side="2"] .dice2-dot:nth-of-type(2) {
    grid-area: nine;
}

[dice2-data-side="3"] .dice2-dot:nth-of-type(1) {
    grid-area: one;
}

[dice2-data-side="3"] .dice2-dot:nth-of-type(2) {
    grid-area: five;
}

[dice2-data-side="3"] .dice2-dot:nth-of-type(3) {
    grid-area: nine;
}

[dice2-data-side="4"] .dice2-dot:nth-of-type(1) {
    grid-area: one;
}

[dice2-data-side="4"] .dice2-dot:nth-of-type(2) {
    grid-area: three;
}

[dice2-data-side="4"] .dice2-dot:nth-of-type(3) {
    grid-area: seven;
}

[dice2-data-side="4"] .dice2-dot:nth-of-type(4) {
    grid-area: nine;
}

[dice2-data-side="5"] .dice2-dot:nth-of-type(1) {
    grid-area: one;
}

[dice2-data-side="5"] .dice2-dot:nth-of-type(2) {
    grid-area: three;
}

[dice2-data-side="5"] .dice2-dot:nth-of-type(3) {
    grid-area: five;
}

[dice2-data-side="5"] .dice2-dot:nth-of-type(4) {
    grid-area: seven;
}

[dice2-data-side="5"] .dice2-dot:nth-of-type(5) {
    grid-area: nine;
}

[dice2-data-side="6"] .dice2-dot:nth-of-type(1) {
    grid-area: one;
}

[dice2-data-side="6"] .dice2-dot:nth-of-type(2) {
    grid-area: three;
}

[dice2-data-side="6"] .dice2-dot:nth-of-type(3) {
    grid-area: four;
}

[dice2-data-side="6"] .dice2-dot:nth-of-type(4) {
    grid-area: six;
}

[dice2-data-side="6"] .dice2-dot:nth-of-type(5) {
    grid-area: seven;
}

[dice2-data-side="6"] .dice2-dot:nth-of-type(6) {
    grid-area: nine;
}

/* --------- */