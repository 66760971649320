.winning-animation-timer {
    animation: transition 0.5s linear 10s forwards;
}

.winning-animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.winning-animation-ball {
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    animation: ballGrow 2s ease-out forwards, ballTranslate 2s cubic-bezier(0.5, 0, 0.5, 1) 2s;
    transform: scale(0.175);
    opacity: 0;
    backface-visibility: hidden;
}

.winning-animation-ball img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.winning-animation-number {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center -50px;
    z-index: 2;
    opacity: 0.8;
    backface-visibility: hidden;
    animation: none;
    animation-fill-mode: forwards;
}

.winning-animation-stage {
    perspective: 1200px;
    perspective-origin: 50% 50%;
    z-index: 0;
    /* Add this line */
}

.winning-animation-stage:nth-child(1) .winning-animation-ball {
    animation-delay: 11s, 11s;
}

.winning-animation-stage:nth-child(2) .winning-animation-ball {
    animation-delay: 15s, 15s;
}

.winning-animation-stage:nth-child(3) .winning-animation-ball {
    animation-delay: 19s, 19s;
}

.winning-animation-stage:nth-child(4) .winning-animation-ball {
    animation-delay: 23s, 23s;
}

.winning-animation-stage:nth-child(5) .winning-animation-ball {
    animation-delay: 27s, 27s;
}

.winning-animation-stage:nth-child(6) .winning-animation-ball {
    animation-delay: 31s, 31s;
}

.winning-animation-stage:nth-child(7) .winning-animation-ball {
    animation-delay: 35s, 35s;
}

.winning-animation-stage:nth-child(8) .winning-animation-ball {
    animation-delay: 39s, 39s;
}

@keyframes ballGrow {
    0% {
        transform: scale(0.175);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes ballTranslate {
    0% {
        transform: translateY(-100%) scale(0.175);
    }

    100% {
        transform: translateY(0) scale(1);
    }
}

@keyframes countdown {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(deg);
    }
}

@keyframes transition {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes rotateAroundBall {
    0% {
        transform: translate(-50%, -50%) rotateX(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotateX(-1800deg);
        /* 5 rotations */
    }
}