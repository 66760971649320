.nft-img-container {
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;

    &.active {
        border: 2px solid #FE0000 !important;
        box-shadow: 0 -2px 15px 1px #FE0000 !important;
    }

    .img-container {
        position: relative;

        .howrare-rank {
            color: white;
            background: rgba(0, 0, 0, 0.6);
            font-weight: 700;
            font-size: .75rem;
            line-height: 1rem;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            border: 2px solid rgb(168 85 247);
            border-radius: 0.25rem;
            gap: 0.25rem;
            align-items: center;
            display: flex;
            z-index: 30;
            left: 0.5rem;
            bottom: 0.5rem;
            position: absolute;
            margin: 0;
        }

        .fp-text {
            color: white;
            background: rgba(0, 0, 0, 0.5);
            font-weight: 700;
            font-size: .75rem;
            line-height: 1rem;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            border: 2px solid rgb(168 85 247);
            border-radius: 0.25rem;
            gap: 0.25rem;
            align-items: center;
            display: flex;
            z-index: 30;
            right: 0.5rem;
            bottom: 0.5rem;
            position: absolute;
            margin: 0;
        }
    }

    .nft-name {
        color: black;
        padding: 10px;
        font-size: 20px;
    }

    img {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

.quests-action-btn {
    &:hover {
        transform: scale(1.02);
        box-shadow: 0 0 10px 0.5px rgb(253, 253, 253);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
}