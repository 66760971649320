@import './winningAnimation.scss';

.solanaball {
    .prize-box {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 10px;
            border: 2px solid transparent;
            border-width: 3px 0px 3px 0px;
            background: linear-gradient(45deg,
                    #9F263C,
                    #A52483,
                    #9D64B5,
                    #1360B1,
                    #00A5FE) border-box;
            -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
        }
    }

    .hover-button:hover {
        background-color: #03060A;
        color: #fff;
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.2);
      }
           

    .hover-effect {
        transition: color 0.3s ease, filter 0.3s ease;
    }

    .spin-button:active {
        transform: scale(0.9);
        transition: transform 0.2s;
      }           
    
    .hover-effect:hover h5,
    .hover-effect:hover img {
        color: #ffffff;
        filter: brightness(0) invert(1);
    }
    
    .buy-ticket-selector {
        border-image-source: linear-gradient(88.49deg, #9F263C 0.89%, #A52483 13.08%, #9D64B5 44.27%, #1360B1 83.02%, #00A5FE 100%);
        border-image-slice: 1;
        background: linear-gradient(0deg, #1D262F 0%, rgba(0, 0, 0, 0) 100%);
    }

    .buy-ticket:hover {
        transform: scale(1.02);
        box-shadow: 0 0 10px 0.5px rgb(253, 253, 253);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .active-btn {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 12px 30px rgba(237, 201, 103, 0.5), inset 0px 0px 10px 5px rgba(237, 201, 103, 0.5);
        background: linear-gradient(#03060A, #03060A) padding-box,
            linear-gradient(90deg, #EDC967, #D2AC47, #F7EFA8, #AE8625) border-box;

        .active-ticket-count {
            font-weight: 800;
            background: linear-gradient(270.31deg, #EDC967 0.27%, #D2AC47 19.97%, #F7EFA8 61.53%, #AE8625 99.73%), #808080;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }

        .active-discount-rate {
            background: linear-gradient(270.31deg, #EDC967 0.27%, #D2AC47 19.97%, #F7EFA8 61.53%, #AE8625 99.73%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }

    .my-tickets-list {
        &>div {
            display: flex;
            justify-content: center;
        }
    }
}