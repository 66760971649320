.shuffle-countdown-bar {
    background: #000000;
    font-size: 15px; /* Adjust the value as needed */
    font-weight: bold;
    height: 38px; /* Increase this value to make the bar thicker */
    border-radius: 0; /* Set border-radius to 0 to remove rounded edges */

    @media only screen and (max-width: 768px) {
        height: 25px;
        font-size: 12px;
        font-weight: 400;
    }

    .progress-bar {
        max-width: 102%;
        transform-origin: left center;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 75.98%), linear-gradient(90deg, rgba(7, 153, 240, 0.6) 0%, rgba(7, 153, 240, 0.6) 55%, rgba(255, 228, 155, 0.6) 76.46%, rgba(237, 201, 103, 0.6) 100%);
        animation: roundtime calc(var(--duration) * 1ms) linear forwards;
        height: 38px; /* Increase this value to make the bar thicker */

        @media only screen and (max-width: 768px) {
            height: 25px;
    }
    }

    @keyframes roundtime {
        from {
            /* More performant than `width` */
            width: calc(100% * calc(var(--duration) / var(--initial)));
        }

        to {
            /* More performant than `width` */
            width: 0;
        }
    }
}

@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
  
.fade-in {
    animation: fade-in 1s ease-in-out;
}

.solanaicon {
    content: url("../../assets/Solanaicon.svg");
    margin-left: 8px;
    width: 35px;
}
  
  
.wider-winner {
    width: 380px; /* Adjust the value as needed */
  }
  

.left-header {
    background: linear-gradient(95.07deg, rgba(5, 187, 244, 0.3) 4.9%, rgba(104, 153, 248, 0.3) 16.09%, rgba(10, 57, 180, 0.3) 44.74%, rgba(19, 96, 177, 0.3) 80.33%, rgba(0, 165, 254, 0.3) 95.92%);
}

.right-header {
     background: linear-gradient(90deg, rgba(174, 134, 37, 0.3) 0%, rgba(247, 239, 168, 0.3) 46.35%, rgba(210, 172, 71, 0.3) 75.52%, rgba(237, 201, 103, 0.3) 100%);
}

.end-gradient-bg {
    background: radial-gradient(50% 50% at 50% 50%, #808080 0%, rgba(128, 128, 128, 0) 68.42%, rgba(128, 128, 128, 0.4) 100%);
}

.center-gradient-bg {
    background: linear-gradient(90deg, rgba(7, 153, 240, 0.3) 0%, rgba(65, 100, 181, 0.3) 34.9%, rgba(163, 53, 128, 0.3) 69.79%, rgba(161, 39, 70, 0.3) 100%);
}

.winning-chance-bar {
    background: linear-gradient(
      90deg,
      #9F263C 0%,
      #FF7F00 25%,
      #FFFF00 50%,
      #46FF78 75%,
      #0000FF 100%
    );
  }
  

.sol-enter-btn {
    position: relative;
    background: linear-gradient(90deg,
            rgba(5, 244, 230, 0.1),
            rgba(104, 153, 248, 0.1),
            rgba(10, 57, 180, 0.1),
            rgba(19, 96, 177, 0.1),
            rgba(0, 165, 254, 0.1));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(5, 244, 230, 1),
                rgba(104, 153, 248, 1),
                rgba(10, 57, 180, 1),
                rgba(19, 96, 177, 1),
                rgba(0, 165, 254, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.no-wrap {
    white-space: nowrap;
}

.pl-extra {
    padding-right: 15px;
}

.last-winner-text {
    font-size: 12px;
    color: #FFFFFF;
    font-weight: medium;
    line-height: 17px;
    white-space: nowrap;
    animation: flash 2s infinite; /* Adjust the duration as needed */
  }
  

@keyframes flash {
    0%, 100% {
      color: #FFFFFF;
    }
    50% {
      color: #46ff78;
    }
  }  

.sol-nft-button:hover {
    transform: scale(1.02);
    box-shadow: 0 0 10px 0.5px rgb(253, 253, 253);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.winner:hover {
    transform: scale(1.02);
    box-shadow: 0 0 10px 0.5px #e6c45d;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.nft-enter-btn {
    position: relative;
    background: linear-gradient(90deg,
            rgba(174, 134, 37, 0.1),
            rgba(247, 239, 168, 0.1),
            rgba(210, 172, 71, 0.1),
            rgba(237, 201, 103, 0.1));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(174, 134, 37, 1),
                rgba(247, 239, 168, 1),
                rgba(210, 172, 71, 1),
                rgba(237, 201, 103, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.wheel {
    transition: transform 7s;
    transform: rotate(0deg);
}

.nft-img-container {
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;

    &.active {
        border: 2px solid #FE0000 !important;
        box-shadow: 0 -2px 15px 1px #FE0000 !important;
    }

    .img-container {
        position: relative;

        .howrare-rank {
            color: white;
            background: rgba(0, 0, 0, 0.6);
            font-weight: 700;
            font-size: .75rem;
            line-height: 1rem;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            border: 2px solid rgb(168 85 247);
            border-radius: 0.25rem;
            gap: 0.25rem;
            align-items: center;
            display: flex;
            z-index: 30;
            left: 0.5rem;
            bottom: 0.5rem;
            position: absolute;
            margin: 0;
        }

        .fp-text {
            color: white;
            background: rgba(0, 0, 0, 0.5);
            font-weight: 700;
            font-size: .75rem;
            line-height: 1rem;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            border: 2px solid rgb(168 85 247);
            border-radius: 0.25rem;
            gap: 0.25rem;
            align-items: center;
            display: flex;
            z-index: 30;
            right: 0.5rem;
            bottom: 0.5rem;
            position: absolute;
            margin: 0;
        }
    }

    .nft-name {
        color: black;
        padding: 10px;
        font-size: 20px;
    }

    img {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

.last-winner-mobile {
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @media only screen and (min-width: 768px) {
    .last-winner-mobile {
      position: static;
      transform: none;
      top: auto;
      left: auto;
    }
  }

  .mobile-height {
    height: 100%;
  }
  
  @media (max-width: 639px) {
    .mobile-height {
      height: 110%;
    }
  }
  
  