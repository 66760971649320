.fund-modal-tab-item {
  padding: 0 24px;
  font-family: "Tisa Sans Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  height: 50px;
  color: #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover:not(.active) {
    transform: scale(1.2);
    transform: translateY(-2px);
    filter: brightness(110%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  &.active {
    border-left: 3px solid;
    border-image-source: linear-gradient(
      196deg,
      #9f263c 45.19%,
      #a52483 51.93%,
      #9d64b5 69.18%,
      #1360b1 90.61%,
      #00a5fe 100%
    );
    border-image-slice: 1;
    background-color: #2d2d2d;
    color: white;
    font-weight: bold;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.2),
      0 2px 0 0 rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.2);
  }
}

.first-card {
  background: linear-gradient(180deg, #3a4247 7.89%, #090c0e 99.19%) padding-box,
    linear-gradient(
        90deg,
        #ae8625 0%,
        #f7efa8 46.35%,
        #d2ac47 75.52%,
        #edc967 100%
      )
      border-box;
  border: 3px solid transparent;
}

.hover-enabled:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.hide-when-flipped {
  opacity: 0;
}

.second-card {
  background: linear-gradient(180deg, #3a4247 7.89%, #090c0e 99.19%) padding-box,
    linear-gradient(
        90deg,
        rgba(7, 153, 240, 0.6) 0%,
        rgba(7, 153, 240, 0.6) 55%,
        rgba(255, 228, 155, 0.6) 76.46%,
        rgba(237, 201, 103, 0.6) 100%
      )
      border-box;
  border: 3px solid transparent;
}

.third-card {
  background: linear-gradient(180deg, #3a4247 7.89%, #090c0e 99.19%);
  border: 3px solid #00a5fe;
}

.animating-gift {
  animation: resize-image 1.5s infinite alternate;
}

.glow-svg {
  filter: drop-shadow(0 0 8px rgba(255, 215, 0, 0.8));
}

@keyframes resize-image {
  from {
    transform: scale(1);
    /* initial size */
  }

  to {
    transform: scale(1.2);
    /* increased size */
  }
}

/* card-flip */
.card-flip-container {
  perspective: 3000px;
}

.card-flip {
  transform-style: preserve-3d;
  transition: transform 0.5s;
  position: relative;

  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* for Safari and Chrome */
    -moz-backface-visibility: hidden; /* for Firefox */
    -ms-backface-visibility: hidden; /* for Internet Explorer */
    -o-backface-visibility: hidden; /* for Opera */
  }
}

.gift-model-button {
  box-shadow: inset 0px 0px 8px 0px #00a5fe;
  transition: box-shadow 0.3s ease;
}

.gift-model-button:hover {
  box-shadow: 0px 0px 8px 0px #00a5fe;
}

.front {
  transform: rotateY(180deg);
}

.flipped {
  transform: rotateY(180deg);
}

@keyframes changeSunlight {
  0% {
    filter: brightness(100%) hue-rotate(0deg);
  }
  50% {
    filter: brightness(150%) hue-rotate(10deg);
  }
  100% {
    filter: brightness(100%) hue-rotate(0deg);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.landing-text {
  animation: fadeInOut 6s linear infinite; /* 2s duration, infinite loop */
  text-align: center;
}

.landing-page {
  animation: changeSunlight 10s infinite;
}
/* --------- */
