.coinflip-countdown-bar {
    background: #000000;
    font-size: 15px;
    /* Adjust the value as needed */
    font-weight: bold;
    height: 38px;
    /* Increase this value to make the bar thicker */
    border-radius: 0;
    /* Set border-radius to 0 to remove rounded edges */

    @media only screen and (max-width: 768px) {
        height: 25px;
        font-size: 12px;
        font-weight: 400;
    }


    .progress-bar {
        max-width: 102%;
        transform-origin: left center;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 75.98%), linear-gradient(90deg, rgba(7, 153, 240, 0.6) 0%, rgba(7, 153, 240, 0.6) 55%, rgba(255, 228, 155, 0.6) 76.46%, rgba(237, 201, 103, 0.6) 100%);
        animation: roundtime calc(var(--duration) * 1ms) linear forwards;
        height: 38px;
        /* Increase this value to make the bar thicker */

        @media only screen and (max-width: 768px) {
            height: 25px;
        }
    }

    @keyframes roundtime {
        from {
            /* More performant than `width` */
            width: calc(100% * calc(var(--duration) / var(--initial)));
        }

        to {
            /* More performant than `width` */
            width: 0;
        }
    }
}

.last-flip-glow-and-fade {
    will-change: transform, opacity;
    animation-name: fadeInDropBounce;
    animation-duration: 500ms;
    /* adjust the duration as desired */
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    box-shadow: 0 0 6px rgba(255, 255, 255, 0.6), 0 0 6px rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    /* Adjust this value to match the shape of your image */
}

@keyframes fadeInDropBounce {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }

    30% {
        opacity: 1;
        transform: translateY(0);
    }

    70% {
        transform: translateY(-10%);
        /* control the bounce height by adjusting this value */
    }

    100% {
        transform: translateY(0);
    }
}

.fifth-flip {
    will-change: transform, opacity;
    animation-name: moveDownFadeOut;
    animation-duration: 500ms;
    /* adjust the duration as desired */
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.roll-ccw {
    will-change: transform;
    transform-origin: center;
    animation-name: rollCounterClockwise;
    animation-duration: 500ms;
    /* adjust the duration as desired */
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes rollCounterClockwise {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
        /* adjust the value to control the distance it moves */
    }
}

.left-coinflip-header {
    background: linear-gradient(95.07deg, rgba(5, 187, 244, 0.3) 4.9%, rgba(104, 153, 248, 0.3) 16.09%, rgba(10, 57, 180, 0.3) 44.74%, rgba(19, 96, 177, 0.3) 80.33%, rgba(0, 165, 254, 0.3) 95.92%);
}

.right-coinflip-header {
    background: linear-gradient(90deg, rgba(174, 134, 37, 0.3) 0%, rgba(247, 239, 168, 0.3) 46.35%, rgba(210, 172, 71, 0.3) 75.52%, rgba(237, 201, 103, 0.3) 100%);
}

.onchain-hover-bet-buttons {
    position: relative;
}

.onchain-hover-bet-buttons.active {
    background-color: #03060A;
    box-shadow: 0px 0px 9.14062px #00A5FE;
    border: 2px solid #00A5FE;
    transition: background-color 0.3s ease;
    transform: translateY(2px);
}

.onchain-hover-bet-buttons.active:hover {
    background-color: #03060A;
}

/* Free bet */
.free-bet-buttons {
    position: relative;
}

.free-bet-buttons.active {
    background-color: #03060A;
    box-shadow: 0px 0px 9.14062px #46ff78;
    border: 2px solid #46ff78;
    transition: background-color 0.3s ease;
    transform: translateY(2px);
    color: #46ff78;
}

.free-bet-buttons.active:hover {
    background-color: #03060A;
}

/* -------- */

.head-btn {
    position: relative;
    background: linear-gradient(90deg,
            rgba(5, 244, 230, 0.1),
            rgba(104, 153, 248, 0.1),
            rgba(10, 57, 180, 0.1),
            rgba(19, 96, 177, 0.1),
            rgba(0, 165, 254, 0.1));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(5, 244, 230, 1),
                rgba(104, 153, 248, 1),
                rgba(10, 57, 180, 1),
                rgba(19, 96, 177, 1),
                rgba(0, 165, 254, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.tail-btn {
    position: relative;
    background: linear-gradient(90deg,
            rgba(174, 134, 37, 0.1),
            rgba(247, 239, 168, 0.1),
            rgba(210, 172, 71, 0.1),
            rgba(237, 201, 103, 0.1));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(174, 134, 37, 1),
                rgba(247, 239, 168, 1),
                rgba(210, 172, 71, 1),
                rgba(237, 201, 103, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.processing-btn {
    position: relative;
    background: linear-gradient(90deg,
            rgba(87, 87, 87, 0.1),
            rgba(219, 219, 219, 0.1),
            rgba(124, 124, 124, 0.1),
            rgba(128, 128, 128, 0.1));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(87, 87, 87, 1),
                rgba(219, 219, 219, 1),
                rgba(124, 124, 124, 1),
                rgba(128, 128, 128, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.claiming-btn {
    position: relative;
    background: linear-gradient(90deg,
            rgba(3, 119, 3, 0.1),
            rgba(3, 119, 3, 0.1));
    border-radius: 9999px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 2px solid transparent;
        background: linear-gradient(45deg,
                rgba(3, 119, 3, 1),
                rgba(3, 119, 3, 1)) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

.heads-tails-button:hover {
    transform: scale(1.02);
    box-shadow: 0 0 10px 0.5px rgb(253, 253, 253);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.win-board {
    // border: 3.5px solid rgba(0, 255, 0, 0.5);
    border-radius: 2px;
    box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 0.5), 0px 0px 16px 2px rgba(0, 255, 0, 0.5);
    transition: all 0.5s ease;
    animation: pulse 2s infinite;
}

.relative {
    position: relative;
}

.special-hover {
    display: block;
    position: absolute;
    background: #00A5FE;
    color: white;
}

.special-hover.free-bet {
    display: block;
    position: absolute;
    background: #46ff78;
    color: #46ff78;
}

.special-hover:nth-child(1) {
    left: -2px;
    bottom: -2px;
    width: 2px;
    height: calc(100% + 4px);
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s;
}

button:hover .special-hover:nth-child(1) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.2s;
}

.special-hover:nth-child(2) {
    left: 0;
    bottom: -2px;
    width: calc(100% + 2px);
    height: 2px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s;
}

button:hover .special-hover:nth-child(2) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.2s;
}

.special-hover:nth-child(3) {
    right: -2px;
    bottom: 0;
    width: 2px;
    height: calc(100% + 2px);
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

button:hover .special-hover:nth-child(3) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

.special-hover:nth-child(4) {
    left: 0;
    top: -2px;
    width: calc(100% + 2px);
    height: 2px;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

button:hover .special-hover:nth-child(4) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

/* Updated selectors from button:hover to .onchain-hover-bet-buttons:hover */
.onchain-hover-bet-buttons:hover .special-hover:nth-child(1) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.2s;
}

.onchain-hover-bet-buttons:hover .special-hover:nth-child(2) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.2s;
}

.onchain-hover-bet-buttons:hover .special-hover:nth-child(3) {
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

.onchain-hover-bet-buttons:hover .special-hover:nth-child(4) {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.2s;
    transition-delay: 0.2s;
}

/* Add these new rules to override the hover effect for the active state */
.onchain-hover-bet-buttons.active:hover .special-hover:nth-child(1),
.onchain-hover-bet-buttons.active:hover .special-hover:nth-child(3) {
    transform: scaleY(0);
    transition: none;
}

.onchain-hover-bet-buttons.active:hover .special-hover:nth-child(2),
.onchain-hover-bet-buttons.active:hover .special-hover:nth-child(4) {
    transform: scaleX(0);
    transition: none;
}

.win-board:hover {
    border: 3.5px solid rgba(0, 255, 0, 1);
    box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 1), 0px 0px 16px 2px rgba(0, 255, 0, 1);
    transform: translateY(-0.1px);
    /* Reduce the amount of translateY */
    transition: transform 0.3s ease;
}

.win-header {
    background: linear-gradient(90deg, rgba(55, 207, 96, 0.3) 0%, rgba(154, 255, 181, 0.3) 46.35%, rgba(0, 185, 63, 0.3) 75.52%, rgba(0, 255, 133, 0.3) 100%);
    transition: transform 0.3s ease;
    /* Add transition effect */
}

.win-numbers {
    color: #46FF78;
    transition: transform 0.3s ease;
    /* Add transition effect */
}

.win-user-group {
    content: url("../../assets/win-user-group.svg");
}

.win-user {
    content: url("../../assets/win-user.svg");
}

.win-sol-symbol {
    content: url("../../assets/win-sol-symbol.svg");
}

.recent-flip-image {
    opacity: 0;
    transition: opacity 0.5s ease;
    image-rendering: -webkit-optimize-contrast;
}

.recent-flip-image.loaded {
    opacity: 1;
}

/* Coinflip animation */
#coin {
    position: relative;
    margin: 0 auto;
    will-change: transform;
    width: 250px;
    height: 250px;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
        height: 140px;
        width: 140px;
    }
}

#coin div {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.side-a-chill {
    background-image: url("../../assets/tail-large-chill.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.side-b-chill {
    background-image: url("../../assets/head-large-chill.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

#coin {
    transition: -webkit-transform 1s ease-in;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

#coin div {
    position: absolute;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.side-a {
    z-index: 100;
}

.side-b {
    transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
}

#coin.heads {
    -webkit-animation: flipHeads 3s ease-out forwards;
    -moz-animation: flipHeads 3s ease-out forwards;
    -o-animation: flipHeads 3s ease-out forwards;
    animation: flipHeads 3s ease-out forwards;
}

#coin.tails {
    -webkit-animation: flipTails 3s ease-out forwards;
    -moz-animation: flipTails 3s ease-out forwards;
    -o-animation: flipTails 3s ease-out forwards;
    animation: flipTails 3s ease-out forwards;
}

@keyframes flipTails {
    0% {
        -webkit-transform: rotateX(0) scale(1);
        -moz-transform: rotateX(0) scale(1);
        transform: rotateX(0) scale(1);
        -webkit-animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: rotateX(1080deg) scale(2.2);
        -moz-transform: rotateX(1080deg) scale(2.2);
        transform: rotateX(1080deg) scale(2.2);
        -webkit-animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: rotateX(2520deg) scale(1);
        -moz-transform: rotateX(2520deg) scale(1);
        transform: rotateX(2520deg) scale(1);
        -webkit-animation-timing-function: ease-out;
    }
}

@-webkit-keyframes flipTails {
    0% {
        -webkit-transform: rotateX(0) scale(1);
        -moz-transform: rotateX(0) scale(1);
        transform: rotateX(0) scale(1);
        -webkit-animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: rotateX(1080deg) scale(2.2);
        -moz-transform: rotateX(1080deg) scale(2.2);
        transform: rotateX(1080deg) scale(2.2);
        -webkit-animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: rotateX(2520deg) scale(1);
        -moz-transform: rotateX(2520deg) scale(1);
        transform: rotateX(2520deg) scale(1);
        -webkit-animation-timing-function: ease-out;
    }
}

@keyframes flipHeads {
    0% {
        -webkit-transform: rotateX(0) scale(1);
        -moz-transform: rotateX(0) scale(1);
        transform: rotateX(0) scale(1);
        -webkit-animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: rotateX(1080deg) scale(2.2);
        -moz-transform: rotateX(1080deg) scale(2.2);
        transform: rotateX(1080deg) scale(2.2);
        -webkit-animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: rotateX(2340deg) scale(1);
        -moz-transform: rotateX(2340deg) scale(1);
        transform: rotateX(2340deg) scale(1);
        -webkit-animation-timing-function: ease-out;
    }
}

@-webkit-keyframes flipHeads {
    0% {
        -webkit-transform: rotateX(0) scale(1);
        -moz-transform: rotateX(0) scale(1);
        transform: rotateX(0) scale(1);
        -webkit-animation-timing-function: ease-out;
    }

    50% {
        -webkit-transform: rotateX(1080deg) scale(2.2);
        -moz-transform: rotateX(1080deg) scale(2.2);
        transform: rotateX(1080deg) scale(2.2);
        -webkit-animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: rotateX(2340deg) scale(1);
        -moz-transform: rotateX(2340deg) scale(1);
        transform: rotateX(2340deg) scale(1);
        -webkit-animation-timing-function: ease-out;
    }
}

@keyframes pulse {

    0%,
    100% {
        box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 0.5),
            0px 0px 16px 2px rgba(0, 255, 0, 0.5);
    }

    50% {
        box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 1),
            0px 0px 16px 2px rgba(0, 255, 0, 1);
    }
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 4px rgba(255, 255, 255, 0.6), 0 0 4px rgba(255, 255, 255, 0.6);
    }

    50% {
        box-shadow: 0 0 6px rgba(255, 255, 255, 0.6), 0 0 6px rgba(255, 255, 255, 0.6);
    }

    100% {
        box-shadow: 0 0 4px rgba(255, 255, 255, 0.6), 0 0 4px rgba(255, 255, 255, 0.6);
    }
}

@keyframes moveDownFadeOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(50px);
        /* adjust the value to control the distance it moves */
    }
}

@keyframes coinflipSpinAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.coinflip-spin {
    animation: coinflipSpinAnimation 0.6s linear infinite;
}

/* ------------------ */