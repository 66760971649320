.coinflip-adrenaline-countdown-bar {
  background: #000000;
  font-size: 15px;
  /* Adjust the value as needed */
  font-weight: bold;
  height: 38px;
  /* Increase this value to make the bar thicker */
  border-radius: 0;
  /* Set border-radius to 0 to remove rounded edges */

  @media only screen and (max-width: 768px) {
    height: 25px;
    font-size: 12px;
    font-weight: 400;
  }

  .progress-bar-adrenaline {
    max-width: 100%;
    transform-origin: left center;
    border-radius: 10px 30px 30px 10px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.2) 75.98%
      ),
      linear-gradient(
        90deg,
        rgba(255, 0, 0, 0.3) 0%,
        rgba(42, 226, 223, 0.3) 100%
      );
    animation: roundtime calc(var(--duration) * 1ms) linear forwards;
    height: 38px;
    /* Increase this value to make the bar thicker */

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px 30px 30px 10px;
      border: 1px solid transparent;
      background: linear-gradient(to right, #ff0000 0%, #2ae2df 100%) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      animation: roundtime calc(var(--duration) * 1ms) linear forwards;
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }

    @media only screen and (max-width: 768px) {
      height: 25px;
    }
  }

  @keyframes roundtime {
    from {
      /* More performant than `width` */
      width: calc(100% * calc(var(--duration) / var(--initial)));
      // border-width: 0px;
    }

    to {
      /* More performant than `width` */
      width: 0;
      border-width: 0px;
    }
  }
}

.last-flip-glow-and-fade {
  will-change: transform, opacity;
  animation-name: fadeInDropBounce;
  animation-duration: 500ms;
  /* adjust the duration as desired */
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  box-shadow: 0 0 6px rgba(255, 255, 255, 0.6), 0 0 6px rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  /* Adjust this value to match the shape of your image */
}

@keyframes fadeInDropBounce {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }

  70% {
    transform: translateY(-10%);
    /* control the bounce height by adjusting this value */
  }

  100% {
    transform: translateY(0);
  }
}

.fifth-flip {
  will-change: transform, opacity;
  animation-name: moveDownFadeOut;
  animation-duration: 500ms;
  /* adjust the duration as desired */
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.roll-ccw {
  will-change: transform;
  transform-origin: center;
  animation-name: rollCounterClockwise;
  animation-duration: 500ms;
  /* adjust the duration as desired */
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes rollCounterClockwise {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    /* adjust the value to control the distance it moves */
  }
}

.left-coinflip-header-adrenaline {
  background: linear-gradient(
    95deg,
    rgba(255, 0, 0, 0.3) 4.9%,
    rgba(255, 93, 93, 0.3) 27.17%,
    rgba(99, 0, 0, 0.3) 68.5%,
    rgba(255, 0, 0, 0.3) 95.92%
  );
}

.right-coinflip-header-adrenaline {
  background: linear-gradient(
    90deg,
    rgba(23, 164, 161, 0.3) 0%,
    rgba(42, 226, 223, 0.3) 36.15%,
    rgba(11, 122, 120, 0.3) 66.15%,
    rgba(23, 164, 161, 0.3) 100%
  );
}

.hover-bet-buttons-adrenaline {
  position: relative;
}

.hover-bet-buttons-adrenaline.active {
  background-color: #03060a;
  box-shadow: 0px 0px 9.14062px #ff7f7f;
  border: 2px solid #ff7f7f;
  transition: background-color 0.3s ease;
  transform: translateY(2px);
}

.hover-bet-buttons-adrenaline:hover {
  color: white;
}

.hover-bet-buttons-adrenaline.active:hover {
  background-color: #03060a;
}

/* Visualizer */
.visualizer-container {
  position: absolute;
  width: auto;
  bottom: 6px;
  padding-left: 6px;
}

canvas {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Free bet */
.free-bet-buttons {
  position: relative;
}

.free-bet-buttons.active {
  background-color: #03060a;
  box-shadow: 0px 0px 9.14062px #46ff78;
  border: 2px solid #46ff78;
  transition: background-color 0.3s ease;
  transform: translateY(2px);
  color: #46ff78;
}

.free-bet-buttons:hover {
  color: white;
}

.free-bet-buttons.active:hover {
  background-color: #03060a;
}

/* -------- */

.head-btn-adrenaline {
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 0.1),
    rgba(255, 93, 93, 0.1),
    rgba(99, 0, 0, 0.1),
    rgba(255, 0, 0, 0.1)
  );
  border-radius: 9999px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 9999px;
    border: 2px solid transparent;
    background: linear-gradient(
        45deg,
        rgba(255, 0, 0, 1),
        rgba(255, 93, 93, 1),
        rgba(99, 0, 0, 1),
        rgba(255, 0, 0, 1)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.tail-btn-adrenaline {
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(23, 164, 161, 0.1),
    rgba(42, 226, 223, 0.1),
    rgba(11, 122, 120, 0.1),
    rgba(23, 164, 161, 0.1)
  );
  border-radius: 9999px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 9999px;
    border: 2px solid transparent;
    background: linear-gradient(
        45deg,
        rgba(23, 164, 161, 1),
        rgba(42, 226, 223, 1),
        rgba(11, 122, 120, 1),
        rgba(23, 164, 161, 1)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.heads-tails-button-adrenaline:hover {
  transform: scale(1.02);
  box-shadow: 0 0 10px 0.5px rgb(253, 253, 253);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.win-board-adrenaline {
  border: 2px solid rgba(0, 255, 0, 0.5);
  border-radius: 2px;
  box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 0.5),
    0px 0px 16px 2px rgba(0, 255, 0, 0.5);
  transition: all 0.5s ease;
  animation: pulse 0.5s infinite;
}

.relative {
  position: relative;
}

.special-hover-adrenaline {
  display: block;
  position: absolute;
  background: #ff7f7f;
  color: white;
}

.special-hover-adrenaline.free-bet {
  display: block;
  position: absolute;
  background: #46ff78;
  color: #46ff78;
}

.special-hover-adrenaline:nth-child(1) {
  left: -2px;
  bottom: -2px;
  width: 2px;
  height: calc(100% + 4px);
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s;
}

button:hover .special-hover-adrenaline:nth-child(1) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.2s;
}

.special-hover-adrenaline:nth-child(2) {
  left: 0;
  bottom: -2px;
  width: calc(100% + 2px);
  height: 2px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.2s;
}

button:hover .special-hover-adrenaline:nth-child(2) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.2s;
}

.special-hover-adrenaline:nth-child(3) {
  right: -2px;
  bottom: 0;
  width: 2px;
  height: calc(100% + 2px);
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s;
  transition-delay: 0.2s;
}

button:hover .special-hover-adrenaline:nth-child(3) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.2s;
  transition-delay: 0.2s;
}

.special-hover-adrenaline:nth-child(4) {
  left: 0;
  top: -2px;
  width: calc(100% + 2px);
  height: 2px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.2s;
  transition-delay: 0.2s;
}

button:hover .special-hover-adrenaline:nth-child(4) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.2s;
  transition-delay: 0.2s;
}

/* Updated selectors from button:hover to .hover-bet-buttons-adrenaline:hover */
.hover-bet-buttons-adrenaline:hover .special-hover-adrenaline:nth-child(1) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.2s;
}

.hover-bet-buttons-adrenaline:hover .special-hover-adrenaline:nth-child(2) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.2s;
}

.hover-bet-buttons-adrenaline:hover .special-hover-adrenaline:nth-child(3) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.2s;
  transition-delay: 0.2s;
}

.hover-bet-buttons-adrenaline:hover .special-hover-adrenaline:nth-child(4) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.2s;
  transition-delay: 0.2s;
}

/* Add these new rules to override the hover effect for the active state */
.hover-bet-buttons-adrenaline.active:hover
  .special-hover-adrenaline:nth-child(1),
.hover-bet-buttons-adrenaline.active:hover
  .special-hover-adrenaline:nth-child(3) {
  transform: scaleY(0);
  transition: none;
}

.hover-bet-buttons-adrenaline.active:hover
  .special-hover-adrenaline:nth-child(2),
.hover-bet-buttons-adrenaline.active:hover
  .special-hover-adrenaline:nth-child(4) {
  transform: scaleX(0);
  transition: none;
}

.win-board-adrenaline:hover {
  border: 3.5px solid rgba(0, 255, 0, 1);
  box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 1),
    0px 0px 16px 2px rgba(0, 255, 0, 1);
  transform: translateY(-0.1px);
  /* Reduce the amount of translateY */
  transition: transform 0.3s ease;
}

.win-header {
  background: linear-gradient(
    90deg,
    rgba(55, 207, 96, 0.3) 0%,
    rgba(154, 255, 181, 0.3) 46.35%,
    rgba(0, 185, 63, 0.3) 75.52%,
    rgba(0, 255, 133, 0.3) 100%
  );
  transition: transform 0.3s ease;
  /* Add transition effect */
}

.win-numbers {
  color: #46ff78;
  transition: transform 0.3s ease;
  /* Add transition effect */
}

.win-user-group {
  content: url("../../assets/win-user-group.svg");
}

.win-user {
  content: url("../../assets/win-user.svg");
}

.win-sol-symbol {
  content: url("../../assets/win-sol-symbol.svg");
}

.recent-flip-image {
  opacity: 0;
  transition: opacity 0.5s ease;
  image-rendering: -webkit-optimize-contrast;
}

.recent-flip-image.loaded {
  opacity: 1;
}

/* Coinflip animation */
#coin-adrenaline {
  position: relative;
  margin: 0 auto;
  will-change: transform;
  width: 250px;
  height: 250px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    height: 140px;
    width: 140px;
  }
}

#coin-adrenaline div {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.side-a {
  background-image: url("../../assets/tail-large.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.side-b {
  background-image: url("../../assets/head-large.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#coin-adrenaline {
  transition: -webkit-transform 1s ease-in;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

#coin-adrenaline div {
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.side-a {
  z-index: 100;
}

.side-b {
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
}

#coin-adrenaline.heads-adrenaline {
  -webkit-animation: flipHeads 1s ease-out forwards;
  -moz-animation: flipHeads 1s ease-out forwards;
  -o-animation: flipHeads 1s ease-out forwards;
  animation: flipHeads 1s ease-out forwards;
}

#coin-adrenaline.tails-adrenaline {
  -webkit-animation: flipTails 1s ease-out forwards;
  -moz-animation: flipTails 1s ease-out forwards;
  -o-animation: flipTails 1s ease-out forwards;
  animation: flipTails 1s ease-out forwards;
}

@keyframes flipTails {
  0% {
    -webkit-transform: rotateX(0) scale(1);
    -moz-transform: rotateX(0) scale(1);
    transform: rotateX(0) scale(1);
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: rotateX(1080deg) scale(2.2);
    -moz-transform: rotateX(1080deg) scale(2.2);
    transform: rotateX(1080deg) scale(2.2);
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: rotateX(2520deg) scale(1);
    -moz-transform: rotateX(2520deg) scale(1);
    transform: rotateX(2520deg) scale(1);
    -webkit-animation-timing-function: ease-out;
  }
}

@-webkit-keyframes flipTails {
  0% {
    -webkit-transform: rotateX(0) scale(1);
    -moz-transform: rotateX(0) scale(1);
    transform: rotateX(0) scale(1);
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: rotateX(1080deg) scale(2.2);
    -moz-transform: rotateX(1080deg) scale(2.2);
    transform: rotateX(1080deg) scale(2.2);
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: rotateX(2520deg) scale(1);
    -moz-transform: rotateX(2520deg) scale(1);
    transform: rotateX(2520deg) scale(1);
    -webkit-animation-timing-function: ease-out;
  }
}

@keyframes flipHeads {
  0% {
    -webkit-transform: rotateX(0) scale(1);
    -moz-transform: rotateX(0) scale(1);
    transform: rotateX(0) scale(1);
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: rotateX(1080deg) scale(2.2);
    -moz-transform: rotateX(1080deg) scale(2.2);
    transform: rotateX(1080deg) scale(2.2);
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: rotateX(2340deg) scale(1);
    -moz-transform: rotateX(2340deg) scale(1);
    transform: rotateX(2340deg) scale(1);
    -webkit-animation-timing-function: ease-out;
  }
}

@-webkit-keyframes flipHeads {
  0% {
    -webkit-transform: rotateX(0) scale(1);
    -moz-transform: rotateX(0) scale(1);
    transform: rotateX(0) scale(1);
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: rotateX(1080deg) scale(2.2);
    -moz-transform: rotateX(1080deg) scale(2.2);
    transform: rotateX(1080deg) scale(2.2);
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: rotateX(2340deg) scale(1);
    -moz-transform: rotateX(2340deg) scale(1);
    transform: rotateX(2340deg) scale(1);
    -webkit-animation-timing-function: ease-out;
  }
}

@keyframes pulse {
  0%,
  100% {
    box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 0.5),
      0px 0px 16px 2px rgba(0, 255, 0, 0.5);
  }

  50% {
    box-shadow: inset 0px 0px 16px 2px rgba(0, 255, 0, 1),
      0px 0px 16px 2px rgba(0, 255, 0, 1);
  }
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.6),
      0 0 4px rgba(255, 255, 255, 0.6);
  }

  50% {
    box-shadow: 0 0 6px rgba(255, 255, 255, 0.6),
      0 0 6px rgba(255, 255, 255, 0.6);
  }

  100% {
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.6),
      0 0 4px rgba(255, 255, 255, 0.6);
  }
}

@keyframes moveDownFadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(50px);
    /* adjust the value to control the distance it moves */
  }
}

.adrenaline-slider {
  .MuiSlider-track {
    border: none !important;
    background: linear-gradient(90deg, #ff0000 0%, #2ae2df 100%) !important;
  }

  .MuiSlider-thumb {
    &::after {
      background-image: url("../../assets/slider-thumb.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}

.adrenaline-switch {
  .MuiSwitch-root {
    padding: 0px;
    width: 60px;
    height: 20px;
    overflow: visible;

    @media only screen and (max-width: 768px) {
      width: 50px;
      height: 16px;
    }

    .MuiSwitch-switchBase {
      padding: 0px;
      height: 20px;
      color: transparent;

      &.Mui-checked {
        transform: translateX(40px) !important;

        @media only screen and (max-width: 768px) {
          transform: translateX(35px) !important;
        }
      }

      @media only screen and (max-width: 768px) {
        height: 16px;
      }
    }

    .MuiSwitch-track {
      width: 60px;
      background: linear-gradient(
        to right,
        rgba(42, 226, 223, 0.4),
        rgba(255, 0, 0, 0.4)
      ) !important;
      opacity: 1 !important;
      position: relative !important;
      height: 20px;
      border-radius: 9999px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 9999px;
        border: 1px solid transparent;
        background: linear-gradient(to right, #2ae2df 0%, #ff0000 100%)
          border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
      }

      @media only screen and (max-width: 768px) {
        width: 50px;
        height: 16px;
      }
    }

    .MuiSwitch-input {
      left: 0;
      width: 60px;

      &:checked {
        left: -40px;
      }

      @media only screen and (max-width: 768px) {
        width: 50px;
      }
    }

    .MuiSwitch-thumb {
      width: 45px;
      height: 45px;
      transform: translate(-15px, -8px);
      background-image: url("../../assets/slider-thumb.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      @media only screen and (max-width: 768px) {
        width: 35px;
        height: 35px;
        transform: translate(-12px, -7px);
      }
    }
  }
}

/* ------------------ */
